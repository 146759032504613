import React, { Suspense } from 'react';
import dynamic from 'next/dynamic';
import { StoryBookContext } from '../SBContextContainer';
import SBDesigner from '.';
import { SBActionTarget } from '../SBActionHandler';
import {
  ILibraryScene,
  IStorybook,
  IStorybookPage,
  LoadingStatus
} from '../../../../interfaces';
import {
  getActivatedSceneById,
  saveSceneImage
} from '../../../clientSideServices/library';
import { generateV4UUID } from '../../../../utils/identityGenerator';
import { TDeviceView } from '../../Common/DeviceViewToggle';
import {
  blankPolotnoJson,
  checkForMissingPolotnoPage,
  IPolotnoJSON,
  TLanguage
} from '../../../../mappers/polotno';
import PolotnoSaveWarning from '../../Polotno/PolotnoSaveWarning';
import {
  extractPageId,
  getPolotnoStore,
  scrollPolotnoPageToCenter
} from '../../../../utils/window';
import CSRComponentWrapper from '../../../../components/CSRComponentWrapper';
import {
  getFeaturedImageJson,
  generateStorybookPagePreview,
  getPolotnoJsonInStorybookPage
} from '../../../utils/storybook';
import {
  appendNewValueIntoSetting,
  mapSceneToStorybook,
  mapGeneratedImageToStorybookPage
} from '../../../../mappers/storybook';
import { DID_SAVE_POLOTNO_JSON } from '../../../../utils/constants';
import { logEvent } from '../../../../analytics';
import { uploadBase64ToS3 } from '../../../clientSideServices/aws';
import { StoreType } from 'polotno/model/store';
import { PageConfigContext } from '../../../clientSideServices/pageConfig';
import SBPreview from '../Preview';
import { useDispatch } from 'react-redux';
import { actionHubAlertError } from '../../../../redux/actions';
import { Spinner } from 'react-bootstrap';
import ConfirmationPopup from '../../Common/ConfirmationPopup';
import { useLibraryAction } from '../../../../components/hooks/library';
import { getIdentityId } from '../../../../utils/identity';
import {
  generateBlankScene,
  mapGeneratedImageToSceneContent,
  mapStorybookPageToScene
} from '../../../../mappers/library';
import { useLoadingStatus } from '../../../../components/hooks/loading';
import { getSceneByIdForCheck } from '../../../../clientSideServices/scene';
import { useCollaborations } from '../../../../components/hooks/collaborator';
import SLDescriptionPopup from '../../SceneLibrary/Builder/SLDescriptionPopup';
import { isEmpty } from 'lodash';
import {
  getAvailableLanguageInScene,
  mapCurrentSceneWithNewLanguagePages,
  mapCurrentSceneWithStorePages,
  removeLanguagePageInScene
} from '../../../utils/library';
import {
  generatedImageFromServer,
  removeUnusedFontsInPage,
  removeUnusedFontsInScene
} from '../../../../utils/polotno';
import { HubContext } from '../../HubContext';
import { DID_SELECT_SCENE_FROM_THUMBNAIL } from '../../../utils/analyticsEvents/library';
import SBSceneVersionPopup from './SBSceneVersionPopup';
const PolotnoWrapper = dynamic(() => import('../../Polotno/PolotnoWrapper'), {
  ssr: false
});

type SCENE_DETAILS = {
  name: string;
  description?: string;
};
const SAVE_POLOTNO_LOADING = 'SAVE_POLOTNO_LOADING';

const SBDesignerPolotno = React.memo(() => {
  const dispatch = useDispatch();
  const { state, action } = React.useContext(StoryBookContext);
  const [showEditor, setShowEditor] = React.useState(false);
  const { isLoading, setLoadingStatus } = useLoadingStatus();
  const [activeLanguage, setActiveLanguage] = React.useState<TLanguage>('en');
  const [availableLanguage, setAvailableLanguage] = React.useState<TLanguage[]>(
    ['en']
  );
  const [descriptionPopup, setDescriptionPopup] =
    React.useState<boolean>(false);
  const [duplicatePopup, setDuplicatePopup] = React.useState(null);
  const [sceneVersionPopup, setSceneVersionPopup] = React.useState(null);
  const [sceneDetails, setSceneDetails] = React.useState<SCENE_DETAILS>({
    name: '',
    description: ''
  });
  const saveLoading = isLoading(SAVE_POLOTNO_LOADING);

  const setSaveLoading = (status: boolean) => {
    setLoadingStatus(
      SAVE_POLOTNO_LOADING,
      status ? LoadingStatus.LOADING : LoadingStatus.LOADED
    );
  };

  const setSceneLoading = (id: string, status: boolean) => {
    setLoadingStatus(
      `loading-scene-${id}`,
      status ? LoadingStatus.LOADING : LoadingStatus.LOADED
    );
  };

  const { getActiveSceneById, saveScene, activateScene } = useLibraryAction({
    userId: getIdentityId() || state.storybook?.createdBy,
    context: 'scene'
  });

  const [showFeaturedEditor, setShowFeaturedEditor] = React.useState(false);

  const { storybook } = state;
  const pages = storybook.pages || [];

  const [featureJson, setFeatureJson] = React.useState(
    getFeaturedImageJson(storybook)
  );
  const featureJsonRef = React.useRef(featureJson);
  React.useEffect(() => {
    featureJsonRef.current = featureJson;
  }, [featureJson]);

  const [currentOpenedPage, setCurrentOpenedPage] = React.useState<{
    page: IStorybookPage;
    index: number;
    isImported?: boolean;
  }>();

  const [currentImportedScene, setCurrentImportedScene] =
    React.useState<ILibraryScene>(null);
  const currentImportedSceneRef = React.useRef(currentImportedScene);
  React.useEffect(() => {
    currentImportedSceneRef.current = currentImportedScene;
  }, [currentImportedScene]);

  const [saveWarning, setSaveWarning] = React.useState<TDeviceView>(null);
  const [editWarning, setEditWarning] = React.useState('');
  const [preview, setPreview] = React.useState<{
    loading?: boolean;
    data?: IStorybook;
  }>(null);

  const { collaborators } = useCollaborations({
    itemId: storybook.id,
    context: 'storybook',
    owner: storybook.createdBy
  });

  const handleDuplicate = ({
    sceneId,
    isImported,
    sceneDetails
  }: {
    sceneId: string;
    isImported?: boolean;
    sceneDetails?: any;
  }) => {
    const page = pages.find((p) => p.id === sceneId);
    setSceneLoading(sceneId, true);

    if (!page) return;

    const newId = generateV4UUID();
    const index = pages.indexOf(page);

    const duplicateHelper = async (prevPage: IStorybookPage) => {
      const copy = [...pages];
      const scene = (await Promise.resolve(getSceneByIdForCheck(prevPage.id)))
        .data;
      const prevEditor = JSON.parse(
        scene?.content?.overlay || prevPage.overlay || '{}'
      );

      const hasEditor = prevEditor?.pages?.length > 0;

      const newEditor = hasEditor
        ? JSON.stringify({
            ...prevEditor,
            pages: prevEditor.pages?.map((p) => {
              const { id } = extractPageId(p.id);
              return {
                ...p,
                id: newId + p.id.split(id)[1]
              };
            })
          })
        : null;

      const newPage = {
        ...prevPage,
        id: newId,
        title: `${prevPage.title || ''}(cloned page)`,
        overlay: newEditor,
        sceneDetails
      };

      const newScene = mapStorybookPageToScene({
        page: { ...newPage, title: '' },
        userId: state.storybook.createdBy
      });

      copy.splice(index + 1, 0, { ...newPage, overlay: '' });

      saveScene({
        scene: removeUnusedFontsInScene(newScene),
        onSuccess: () => {
          activateScene({
            id: newScene.id,
            delay: 2700,
            onSuccess: () => {
              setSceneLoading(sceneId, false);
              action.update(SBActionTarget.Storybook, {
                data: {
                  pages: copy
                },
                append: true
              });
            }
          });
        },
        onError: () => doneProcess(newScene)
      });
    };

    if (isImported) {
      getActiveSceneById({
        id: sceneId,
        onSuccess: (scene) => {
          const importedPage = {
            ...mapSceneToStorybook(scene).pages[0],
            overlay: scene.content?.overlay
          };
          duplicateHelper(importedPage);
        }
      });
    } else {
      duplicateHelper(page);
    }
  };

  const handleOpenEditor = ({
    sceneId,
    allowEdit,
    isImported,
    featuredImage,
    activeLanguage: active
  }: {
    sceneId?: string;
    allowEdit?: boolean;
    isImported?: boolean;
    featuredImage?: boolean;
    activeLanguage?: TLanguage;
  }) => {
    if (featuredImage) {
      setShowFeaturedEditor(true);
      setFeatureJson(getFeaturedImageJson(storybook));
      return;
    }

    if (active) {
      setActiveLanguage(active);
    }

    const isCollaborator = collaborators?.includes(getIdentityId()) || false;
    const allowEditScene = isCollaborator ? true : allowEdit;

    if (isImported && !allowEditScene) {
      setEditWarning(sceneId);
      return;
    }

    const page = pages?.find((p) => p.id === sceneId);
    const index = pages?.indexOf(page);

    if (isImported && allowEditScene && page) {
      setSceneLoading(sceneId, true);
      getActiveSceneById({
        id: sceneId,
        onSuccess: (scene) => {
          setCurrentImportedScene(scene);
          setCurrentOpenedPage({
            page: {
              ...mapSceneToStorybook(scene).pages[0],
              overlay: scene.content?.overlay
            },
            index,
            isImported: true
          });
          setSceneLoading(sceneId, false);
          setShowEditor(true);
        },
        onError: () => {
          setSceneLoading(sceneId, false);
        }
      });
      return;
    }
    if (sceneId && !isImported && page) {
      setCurrentOpenedPage({ page, index });
    } else {
      const fakeId = generateV4UUID();
      const blankPages = blankPolotnoJson(fakeId);
      const temporaryScene = generateBlankScene('scene', fakeId);
      temporaryScene.content = {
        ...temporaryScene.content,
        overlay: JSON.stringify(blankPages)
      };
      setCurrentImportedScene({ ...temporaryScene });
      setCurrentOpenedPage({
        index: pages?.length || 0,
        page: {
          ...mapSceneToStorybook(temporaryScene).pages[0],
          overlay: temporaryScene.content?.overlay
        }
      });
    }
    setShowEditor(true);
  };

  const handleSaveFeaturedImage = async (store: StoreType) => {
    const filePath = 'content/converted';
    const bucket = 'inspify-campaignpics';
    const key = `${filePath}/${storybook.id}/featuredImages/${storybook.id}_poster.jpeg`;

    try {
      setSaveLoading(true);
      const image = await generatedImageFromServer([
        {
          json: removeUnusedFontsInPage({
            ...store.toJSON(),
            pages: [store.toJSON().pages[0]]
          }),
          exportOptions: {
            pixelRatio: 1,
            mimeType: 'image/jpeg'
          }
        }
      ]);

      await uploadBase64ToS3({
        Body: image[0] as string,
        Bucket: bucket,
        Key: key,
        ContentType: 'image/jpeg'
      });

      action.update(SBActionTarget.Storybook, {
        data: {
          featuredImage: `${key}?ts=${new Date().getTime()}&manual=true`,
          settings: appendNewValueIntoSetting(storybook.settings, {
            featuredImage: JSON.stringify(store.toJSON())
          })
        },
        append: true
      });

      setSaveLoading(false);
      setShowFeaturedEditor(false);
      setTimeout(() => {
        document.getElementById('pageSettingsGlobal').click();
      }, 200);
    } catch (error) {
      console.error('Uploading file to S3 failed with error', error);
      setSaveLoading(false);
      return Promise.reject(error);
    }
  };

  const doneProcess = (newScene) => {
    const store = getPolotnoStore();
    store.clear();
    setCurrentImportedScene(null);
    setCurrentOpenedPage(null);
    setActiveLanguage('en');
    setAvailableLanguage([]);
    setSceneLoading(newScene.id, false);
    setSaveLoading(false);
    setShowEditor(false);
    resetSceneDetailsPopup();
  };

  const handleSavePageToScene = async () => {
    const pageId = currentOpenedPage?.page?.id || generateV4UUID();

    const images = await saveSceneImage({
      key: `${state.storybook.id}/${state.storybook.version || 1}/${pageId}`,
      scene: mapCurrentSceneWithStorePages(currentImportedScene),
      isNewScene: false
    });

    const mappedScene: ILibraryScene =
      mapCurrentSceneWithStorePages(currentImportedScene);

    const page = mapGeneratedImageToStorybookPage(
      {
        ...currentOpenedPage.page,
        id: pageId,
        overlay: '',
        pageType: 'scene'
      },
      images
    );

    const updatedScene = {
      ...mappedScene,
      content: mapGeneratedImageToSceneContent(
        {
          ...mappedScene.content
        },
        images
      )
    };

    if (updatedScene.content.visibility === 'hide') {
      updatedScene.content.visibility = 'private';
    }

    setSceneLoading(updatedScene.id, true);

    saveScene({
      scene: removeUnusedFontsInScene(
        descriptionPopup
          ? {
              ...updatedScene,
              content: {
                ...updatedScene.content,
                name: sceneDetails.name,
                description: sceneDetails.description
              }
            }
          : updatedScene
      ),
      dontReloadAfterSave: true,
      onSuccess: () => {
        activateScene({
          id: updatedScene.id,
          delay: 2700,
          onSuccess: () => {
            const copy = [...pages];
            const index = copy.findIndex((p) => p.id === page.id);
            if (index !== -1) {
              copy[index].overlay = page.overlay;
              copy[index].thumbnail = page.thumbnail;
              copy[index].url = page.url;
              copy[index].modifiedAt = page.modifiedAt;
              copy[index].settings = JSON.stringify({
                ...JSON.parse(copy[index].settings),
                ...JSON.parse(page.settings)
              });
            }
            action.update(SBActionTarget.Storybook, {
              data: {
                pages: copy
              },
              append: true
            });
            doneProcess(updatedScene);
          },
          onError: () => doneProcess(updatedScene)
        });
      }
    });
  };

  const handleSaveItem = async (store) => {
    const jsonOfPage: IPolotnoJSON = { ...store.toJSON() };

    const missingItem = checkForMissingPolotnoPage(jsonOfPage);

    if (!saveWarning && missingItem && missingItem !== 'mobile') {
      setSaveWarning(missingItem);
      return;
    }

    setSaveLoading(true);

    logEvent(DID_SAVE_POLOTNO_JSON, DID_SAVE_POLOTNO_JSON, {
      storybookId: storybook.id
    });

    try {
      const pageId = currentOpenedPage?.page?.id || generateV4UUID();
      const isNewPage = !pages.find((p) => p.id === pageId);
      const hasPortrait = jsonOfPage.pages.length > 1;

      if (hasPortrait) {
        jsonOfPage.pages = [
          { ...jsonOfPage.pages[0], id: pageId },
          { ...jsonOfPage.pages[1], id: `${pageId}-portrait` }
        ];
      } else {
        jsonOfPage.pages = [
          {
            ...jsonOfPage.pages[0],
            id: pageId
          }
        ];
      }

      const mappedScene: ILibraryScene =
        mapCurrentSceneWithStorePages(currentImportedScene);

      if (!currentOpenedPage || isNewPage) {
        const images = await saveSceneImage({
          key: `${state.storybook.id}/${
            state.storybook.version || 1
          }/${pageId}`,
          scene: mapCurrentSceneWithStorePages(currentImportedScene),
          isNewScene: isNewPage || !currentOpenedPage
        });

        const page = mapGeneratedImageToStorybookPage(
          {
            ...currentOpenedPage.page,
            id: pageId,
            overlay: mappedScene.content.overlay,
            pageType: 'scene'
          },
          images
        );

        const fonts =
          JSON.parse(mappedScene.content.settings)?.customFont || [];

        const newScene = mapStorybookPageToScene({
          page,
          userId: state.storybook.createdBy,
          fonts
        });
        saveScene({
          scene: removeUnusedFontsInScene(
            descriptionPopup
              ? {
                  ...newScene,
                  content: {
                    ...newScene.content,
                    name: sceneDetails.name,
                    description: sceneDetails.description
                  }
                }
              : newScene
          ),
          onSuccess: () => {
            activateScene({
              id: newScene.id,
              delay: 2700,
              onSuccess: () => {
                doneProcess(newScene);
                action.update(SBActionTarget.Storybook, {
                  data: {
                    pages: [
                      ...(state.storybook.pages || []),
                      {
                        ...page,
                        overlay: ''
                      }
                    ]
                  },
                  append: true
                });
              }
            });
          },
          onError: () => doneProcess(newScene)
        });
      } else if (currentOpenedPage && currentOpenedPage.isImported) {
        // START check if scene have new updated version
        const newestScene = await getActivatedSceneById(pageId);
        if (newestScene.version !== currentImportedScene.version) {
          setSceneVersionPopup(newestScene);
          setSaveLoading(false);
          return;
        }
        // END check

        handleSavePageToScene();
      }
    } catch (e) {
      setSaveLoading(false);
      console.log(e);
    }
  };

  const handleSave = (store) => {
    if (!currentImportedScene || isEmpty(currentImportedScene.content?.name)) {
      setDescriptionPopup(store);
    } else {
      handleSaveItem(store);
    }
  };

  const handlePreview = () => {
    const store = getPolotnoStore();
    const updatedScene: ILibraryScene = currentImportedScene?.content?.overlay
      ? mapCurrentSceneWithStorePages(currentImportedScene)
      : {
          ...currentImportedScene,
          content: {
            ...currentImportedScene,
            overlay: JSON.stringify(store?.toJSON())
          }
        };
    setPreview((state) => ({ ...state, loading: true }));
    setCurrentImportedScene(updatedScene);
    setCurrentOpenedPage({
      ...currentOpenedPage,
      page: {
        ...mapSceneToStorybook(updatedScene).pages[0],
        overlay: updatedScene.content?.overlay
      }
    });
    generateStorybookPagePreview({
      page: currentOpenedPage?.page || {},
      onSuccess: (previewPage) => {
        setPreview({
          data: {
            ...storybook,
            pages: [previewPage]
          }
        });
      },
      onLoading: (l) => setPreview((state) => ({ ...state, loading: l })),
      onFailed: (e) => {
        dispatch(actionHubAlertError(e));
        setPreview((state) => ({ ...state, loading: false }));
      }
    });
  };

  const resetSceneDetailsPopup = () => {
    setDescriptionPopup(null);
    setSceneDetails({
      name: '',
      description: ''
    });
  };

  React.useEffect(() => {
    if (currentImportedScene)
      setAvailableLanguage(getAvailableLanguageInScene(currentImportedScene));
  }, [currentImportedScene]);

  const handleAddLanguage = async (store: StoreType, language: TLanguage) => {
    if (store) {
      const mappedScene = mapCurrentSceneWithStorePages(currentImportedScene);
      const updatedScene: ILibraryScene = mapCurrentSceneWithNewLanguagePages(
        mappedScene,
        language
      );
      setCurrentImportedScene(updatedScene);
      setCurrentOpenedPage({
        ...currentOpenedPage,
        page: {
          ...mapSceneToStorybook(updatedScene).pages[0],
          overlay: updatedScene.content?.overlay
        }
      });
      setAvailableLanguage([...availableLanguage, language]);
      setActiveLanguage(language);
    }
  };

  const handleRemoveLanguage = (store: StoreType, language: TLanguage) => {
    if (store) {
      const currentSceneWithEditor =
        mapCurrentSceneWithStorePages(currentImportedScene);
      const updatedScene: ILibraryScene = removeLanguagePageInScene(
        currentSceneWithEditor,
        language
      );
      setCurrentImportedScene(updatedScene);
      setCurrentOpenedPage({
        ...currentOpenedPage,
        page: {
          ...mapSceneToStorybook(updatedScene).pages[0],
          overlay: updatedScene.content?.overlay
        }
      });
      setAvailableLanguage(availableLanguage.filter((l) => l !== language));
      if (language === activeLanguage) setActiveLanguage(availableLanguage[0]);
    }
  };

  const handleSelectLanguage = async (
    store: StoreType,
    language: TLanguage
  ) => {
    setActiveLanguage(language);
    const currentSceneWithEditor: ILibraryScene =
      mapCurrentSceneWithStorePages(currentImportedScene);
    setCurrentImportedScene(currentSceneWithEditor);
  };

  const { user } = React.useContext(HubContext);

  const { getSceneById: getTemplateSceneById } = useLibraryAction({
    userId: user?.id,
    context: 'template'
  });

  const handleLoadTemplateToEditor = (templateId, version, callback) => {
    getTemplateSceneById({
      id: templateId,
      version,
      preventUpdateStatus: true,
      onSuccess: (res) => {
        const overlay = JSON.parse(res.content.overlay);
        const pages = overlay?.pages || [];
        const scenePages =
          JSON.parse(currentImportedSceneRef?.current?.content.overlay || '{}')
            ?.pages || [];
        const store = getPolotnoStore();
        store.loadJSON(overlay, true);
        pages.map((page) => {
          const { language, isPortrait } = extractPageId(page.id);
          const endsWith = `${language === 'en' ? '' : `-${language}`}${
            isPortrait ? '-portrait' : ''
          }`;
          const pageInSceneIdx = scenePages.findIndex((p) =>
            p.id?.includes(endsWith)
          );
          if (pageInSceneIdx !== -1)
            scenePages[pageInSceneIdx] = {
              ...page,
              id:
                scenePages[pageInSceneIdx]?.id ||
                currentImportedSceneRef?.current?.id
            };
          else
            scenePages.push({
              ...page,
              id: `${currentImportedSceneRef?.current?.id}${
                language === 'en' ? '' : `-${language}`
              }${isPortrait ? '-portrait' : ''}`
            });
        });

        const updatedScene = {
          ...currentImportedSceneRef.current,
          content: {
            ...currentImportedSceneRef?.current?.content,
            overlay: JSON.stringify({
              ...JSON.parse(
                currentImportedSceneRef?.current?.content?.overlay || '{}'
              ),
              pages: scenePages
            })
          }
        };

        setCurrentImportedScene(updatedScene);
        setAvailableLanguage(getAvailableLanguageInScene(updatedScene));
        setCurrentOpenedPage({
          ...currentOpenedPage,
          page: {
            ...mapSceneToStorybook(updatedScene).pages[0],
            overlay: updatedScene.content?.overlay
          }
        });
        const canvasEl = document.getElementsByClassName(
          'polotno-page-container'
        );
        if (canvasEl) {
          setTimeout(() => scrollPolotnoPageToCenter(canvasEl), 100);
        }
        callback?.();
      },
      onError: () => {
        callback?.();
      }
    });
    logEvent(DID_SELECT_SCENE_FROM_THUMBNAIL, DID_SELECT_SCENE_FROM_THUMBNAIL, {
      sceneId: templateId,
      sceneVersion: version,
      sceneEditor: true
    });
  };

  const handleLoadTemplateToFeatureImageEditor = (
    templateId,
    version,
    callback
  ) => {
    getTemplateSceneById({
      id: templateId,
      version,
      preventUpdateStatus: true,
      onSuccess: (res) => {
        const overlay = JSON.parse(res.content.overlay);
        const pages = overlay?.pages || [];
        const featurePages = featureJsonRef?.current.pages || [];
        const store = getPolotnoStore();
        store.loadJSON(overlay, true);
        pages.map((page) => {
          const { language, isPortrait } = extractPageId(page.id);
          const endsWith = `${language === 'en' ? '' : `-${language}`}${
            isPortrait ? '-portrait' : ''
          }`;
          const pageInSceneIdx = featurePages.findIndex((p) =>
            p.id?.includes(endsWith)
          );
          if (pageInSceneIdx !== -1)
            featurePages[pageInSceneIdx] = {
              ...page,
              id: featurePages[pageInSceneIdx]?.id || storybook?.id
            };
          else
            featurePages.push({
              ...page,
              id: `${storybook?.id}${language === 'en' ? '' : `-${language}`}${
                isPortrait ? '-portrait' : ''
              }`
            });
        });

        setFeatureJson({ ...featureJsonRef?.current, pages: featurePages });
        const canvasEl = document.getElementsByClassName(
          'polotno-page-container'
        );
        if (canvasEl) {
          setTimeout(() => scrollPolotnoPageToCenter(canvasEl), 100);
        }
        callback?.();
      },
      onError: () => {
        callback?.();
      }
    });
    logEvent(DID_SELECT_SCENE_FROM_THUMBNAIL, DID_SELECT_SCENE_FROM_THUMBNAIL, {
      sceneId: templateId,
      sceneVersion: version,
      sceneEditor: true
    });
  };

  return (
    <>
      {showEditor && (
        <CSRComponentWrapper renderDelay={100}>
          <Suspense fallback={null}>
            <PolotnoWrapper
              id={`${currentOpenedPage?.page?.id} ${state.undoRedo.currentPointer}`}
              isMultiLanguage={true}
              activeLanguage={activeLanguage}
              availableLanguage={availableLanguage}
              initJson={getPolotnoJsonInStorybookPage(currentOpenedPage?.page)}
              onSave={handleSave}
              onAddLanguage={handleAddLanguage}
              onRemoveLanguage={handleRemoveLanguage}
              onSelectLanguage={handleSelectLanguage}
              loading={saveLoading}
              onClose={() => {
                const store = getPolotnoStore();
                store.clear();
                setShowEditor(false);
                setCurrentOpenedPage(null);
                setCurrentImportedScene(null);
                setSaveLoading(false);
                setActiveLanguage('en');
                setAvailableLanguage([]);
              }}
              handleLoadTemplateToEditor={handleLoadTemplateToEditor}
              viewAs={saveWarning}
              context={`editor-${storybook.id}`}
              type={PageConfigContext.STORYBOOK_DESIGNER}
              customActions={
                <button
                  className="btn-round btn-sm btn-outline-dark ml-2"
                  style={{ width: '120px' }}
                  onClick={handlePreview}
                >
                  {preview?.loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    'Preview'
                  )}
                </button>
              }
            />
          </Suspense>
        </CSRComponentWrapper>
      )}

      {showFeaturedEditor && (
        <CSRComponentWrapper renderDelay={100}>
          <Suspense fallback={null}>
            <PolotnoWrapper
              id={storybook.id}
              initJson={featureJson}
              onSave={handleSaveFeaturedImage}
              loading={saveLoading}
              onClose={() => {
                const store = getPolotnoStore();
                store.clear();
                setShowFeaturedEditor(false);
                setSaveLoading(false);
              }}
              context={`editor-${storybook.id}`}
              disableDeviceOption={true}
              handleLoadTemplateToEditor={
                handleLoadTemplateToFeatureImageEditor
              }
              type={PageConfigContext.FEATURED_IMAGE_DESIGNER}
              customActions={
                <button
                  className="btn-round btn-sm btn-outline-dark ml-2"
                  style={{ width: '120px' }}
                  onClick={handlePreview}
                >
                  {preview?.loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    'Preview'
                  )}
                </button>
              }
              excludeSections={['asset-lib-video', 'pixabay']}
            />
          </Suspense>
        </CSRComponentWrapper>
      )}

      {!showEditor && !showFeaturedEditor && (
        <SBDesigner
          onOpenEditor={handleOpenEditor}
          onDuplicate={({ sceneId, isImported }) => {
            setDuplicatePopup({ sceneId, isImported, sceneDetails: {} });
          }}
        />
      )}

      {saveWarning && (
        <PolotnoSaveWarning
          onOk={() => {
            handleSave(getPolotnoStore());
            setSaveWarning(null);
          }}
          onClose={() => setSaveWarning(null)}
          missingItem={saveWarning}
          context="save"
          okLabel="Save without Portrait"
        />
      )}

      {preview?.data && (
        <div className="preview">
          <SBPreview
            storybook={preview.data}
            previewMode="microsite"
            onClose={() => setPreview(null)}
            hidePreviewList={true}
          />
        </div>
      )}

      {editWarning && (
        <ConfirmationPopup
          title="You don't have permission to edit this Scene. Please ask the Scene Owner to invite you as a Collaborator."
          onClose={() => setEditWarning('')}
          closeLabel="Close"
        />
      )}

      {sceneVersionPopup && (
        <SBSceneVersionPopup
          title="Your scene has a new activated version."
          onClose={() => {
            setSceneVersionPopup(null);
            setSaveLoading(false);
          }}
          onOverride={() => {
            setSaveLoading(true);
            setSceneVersionPopup(null);
            handleSavePageToScene();
          }}
          onLoadScene={() => {
            const store = getPolotnoStore();
            store.clear();
            setCurrentImportedScene(sceneVersionPopup);
            setCurrentOpenedPage({
              ...sceneVersionPopup,
              page: {
                ...mapSceneToStorybook(sceneVersionPopup).pages[0],
                overlay: sceneVersionPopup.content?.overlay
              },
              isImported: true
            });
            setSceneVersionPopup(null);
          }}
        />
      )}

      {duplicatePopup && (
        <SLDescriptionPopup
          popupTitle={`Please enter new Scene Details`}
          name={duplicatePopup?.sceneDetails?.name}
          description={duplicatePopup?.sceneDetails?.description}
          onClose={() => setDuplicatePopup(null)}
          onUpdate={(data) =>
            setDuplicatePopup({
              ...duplicatePopup,
              sceneDetails: {
                ...duplicatePopup.sceneDetails,
                ...data
              }
            })
          }
          onSave={() => {
            handleDuplicate(duplicatePopup);
            setDuplicatePopup(null);
          }}
        />
      )}

      {descriptionPopup && (
        <SLDescriptionPopup
          popupTitle={`Please enter Scene Details`}
          name={sceneDetails.name}
          description={sceneDetails.description}
          onClose={resetSceneDetailsPopup}
          onUpdate={(data) =>
            setSceneDetails({
              ...sceneDetails,
              ...data
            })
          }
          loading={saveLoading}
          onSave={() => {
            handleSaveItem(descriptionPopup);
          }}
        />
      )}
      <style jsx global>{`
        .bp5-navbar .device-view-toggle {
          padding: 8px 0;
        }
        #hub-layout > .header > *:not(.logo-container) {
          display: none !important;
        }
        .device-view-toggle {
          display: ${showFeaturedEditor ? 'none' : 'inline-flex'};
        }
      `}</style>
      <style jsx>{`
        .preview {
          z-index: 999;
          background: #fff;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      `}</style>
    </>
  );
});

export default SBDesignerPolotno;
