import React, { useRef } from 'react';
import SBThumbnailCover from './SBThumbnailCover';
import { Spinner } from 'react-bootstrap';
import { logClickButton, logEvent } from '../../../../analytics';
import { DID_CLICK_BUTTON } from '../../../../utils/constants';
import { FileType, IStorybook } from '../../../../interfaces';
import { storyAssetBaseUrl } from '../../../../config';
import { IconFavoriteFilled, IconFolderTrash } from '../../Common/HubIcons';
import { dateFormat } from '../../../../utils/clock';
import UserLabel from '../../Common/UserLabel';
import { isEmpty } from 'lodash';
import { getLanguaImageFromJSON } from '../../../utils/storybook';
import { mapBrowerLanguageToSBLanguage } from '../../../../utils/window';
import { BsThreeDots } from 'react-icons/bs';

const SBThumbnail = ({
  storybook,
  canModify,
  onDelete,
  onRestore,
  onView,
  onSelect,
  selected,
  children,
  loading,
  onClickMenu,
  isInTrash,
  isFavorited,
  deleteMark,
  thumbnailInfo,
  type,
  isBrowseStorybookInMeeting,
  selectedStorybooks,
  selectStorybook,
  isDraggable = false
}: {
  storybook: IStorybook;
  canModify?: boolean;
  onDelete?: (id: string) => void;
  onTransfer?: (storybook: string) => void;
  onRestore?: (id: string) => void;
  onView?: (id: string) => void;
  onSelect?: (storybook: IStorybook) => void;
  selected?: boolean;
  children?: React.ReactNode;
  loading?: boolean;
  onClickMenu?: (canEdit?: boolean) => void;
  isInTrash?: boolean;
  isFavorited?: boolean;
  deleteMark?: boolean;
  thumbnailInfo?: string;
  type?: FileType;
  isBrowseStorybookInMeeting?: boolean;
  selectedStorybooks?: string[];
  selectStorybook?: (scene: IStorybook) => void;
  isDraggable?: boolean;
}) => {
  const {
    title,
    subtitle,
    id,
    pages,
    stats,
    featuredImage: featureString,
    visibility
  } = storybook;
  const language = mapBrowerLanguageToSBLanguage(navigator.language);
  const featuredImage = getLanguaImageFromJSON(featureString, language);
  const firstPageThumbnail = getLanguaImageFromJSON(
    pages?.[0]?.thumbnail || pages?.[0]?.url,
    language
  );
  const thumbnailUrl = featuredImage
    ? `${storyAssetBaseUrl}/${featuredImage}`
    : firstPageThumbnail;

  const containerRef = useRef<HTMLDivElement>(null);

  const logClick = (buttonName: string) => {
    logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
      button: buttonName,
      storybookId: storybook.id
    });
  };

  const externalStorybooks = selectedStorybooks?.filter((sId) => sId !== id);
  const isInSelectedList = selectedStorybooks?.some((sId) => id === sId);

  return (
    <div
      className={`SBThumbnail ${selected ? 'selected' : ''} ${
        deleteMark ? 'deleted' : ''
      }`}
      title={`${title}${subtitle ? ' | ' + subtitle : ''}`}
      ref={containerRef}
      id={`storybook-${id}`}
      draggable={isDraggable && !onSelect}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onDragStart={(e) => {
        containerRef.current?.classList?.add('drag-active');
        e.dataTransfer.setData(
          'storybookIds',
          isInSelectedList ? selectedStorybooks.join(',') : id
        );
        e.dataTransfer.dropEffect = 'move';

        if (isInSelectedList) {
          externalStorybooks.forEach((sId) => {
            document
              .getElementById(`storybook-${sId}`)
              .classList.add('drag-active');
          });
        }
        const ghostElement = document.getElementById(`ghost-ele-${id}`);
        e.dataTransfer.setDragImage(ghostElement, 120, 20);
      }}
      onDragEnd={() => {
        containerRef.current?.classList?.remove('drag-active');
        if (isInSelectedList) {
          externalStorybooks.forEach((aId) => {
            document
              .getElementById(`storybook-${aId}`)
              ?.classList?.remove('drag-active');
          });
        }
      }}
    >
      <div className="position-absolute">
        <div
          className="dragging-elements position-relative"
          id={`ghost-ele-${id}`}
        >
          <div className="dragging-elements-item dragging-element position-absolute shadow-sm">
            <div className="d-flex justify-content-center align-items-center">
              <span className="file-name">{storybook?.title}</span>
            </div>
            <span className="number">
              {isInSelectedList ? selectedStorybooks?.length || 1 : 1}
            </span>
          </div>
          {!isEmpty(externalStorybooks) && isInSelectedList && (
            <div className="dragging-elements-item additional-storybook position-absolute"></div>
          )}
        </div>
      </div>
      <div className="media-container">
        <SBThumbnailCover
          onClick={() => {
            selectStorybook(storybook);
            onView(id);
          }}
          url={thumbnailUrl}
          thumbnailInfo={thumbnailInfo}
          type={type}
        />
        {isFavorited && !isInTrash && (
          <div className="tag-label tag-favorite">
            <IconFavoriteFilled color="#fff" /> Favorite
          </div>
        )}
        {deleteMark && (
          <div className="delete-mark">
            <IconFolderTrash />
          </div>
        )}
        {stats?.canPublish &&
          (stats?.isPublished ? (
            <div className="tag-label tag-published">Published</div>
          ) : (
            <div className="tag-label tag-activated">Activated</div>
          ))}
        {stats && !stats?.canPublish && (
          <div className="tag-label tag-draft">Draft</div>
        )}
        {visibility === 'team' && (
          <div className="tag-label tag-visibility">Team</div>
        )}
        {visibility === 'private' && stats?.canPublish && (
          <div className="tag-label tag-visibility">Private</div>
        )}
      </div>
      <div className="content-container">
        <div className="main-info">
          <div className="title">
            <strong>{title || ' '}</strong>
          </div>
          <div className="subtitle">{subtitle || ' '}</div>
        </div>
        <div className="subtitle info">
          {storybook.createdBy && (
            <>
              <div className="createby-mobile">
                <div>Created by</div>
                <div>
                  <UserLabel id={storybook.createdBy} />
                </div>
              </div>
              <div className="createby-desktop">
                By: <UserLabel id={storybook.createdBy} />
              </div>
            </>
          )}
          {storybook?.createdAt && (
            <div className="created">
              Created: {dateFormat(storybook?.createdAt, 'MMM dd, yyyy')}
            </div>
          )}
          {storybook?.modifiedAt && (
            <div className="modified">
              Modified: {dateFormat(storybook?.modifiedAt, 'MMM dd, yyyy')}
            </div>
          )}
        </div>
        {children}

        <div className="actions">
          <div>
            {onSelect && !isInTrash && (
              <button
                className={`btn sb-select btn-round btn-sm ${
                  selected ? 'btn-outline-dark' : 'btn-dark'
                }`}
                onClick={() => {
                  onSelect(storybook);
                  logClick(selected ? 'Unselect' : 'Select');
                }}
              >
                {isBrowseStorybookInMeeting
                  ? 'Open'
                  : selected
                  ? 'Unselect'
                  : 'Select'}
              </button>
            )}

            {canModify && isInTrash && (
              <>
                <button
                  className={`btn sb-restore btn-round btn-sm btn-outline-dark`}
                  onClick={() => {
                    onRestore(id);
                    logClick('Restore');
                  }}
                >
                  Restore
                </button>
                <button
                  className={`btn sb-delete btn-round btn-sm btn-dark`}
                  onClick={() => {
                    onDelete(id);
                    logClick('Delete');
                  }}
                >
                  Delete
                </button>
              </>
            )}
          </div>

          {!isInTrash && !onSelect && onClickMenu && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                onClickMenu(canModify);
                logClickButton('Storybook Menu');
              }}
              className="sb-option"
              id={`menu-${storybook.id}`}
            >
              <BsThreeDots />
            </div>
          )}
        </div>
      </div>
      {loading && (
        <div className="loading">
          <Spinner animation="border" size="sm" />
        </div>
      )}
      <style jsx>{`
        .createby-mobile {
          display: none;
        }
        .media-container {
          position: relative;
        }
        .sb-option {
          padding: 4px;
          background: #000;
          border-radius: 100%;
        }
        .sb-option :global(svg) {
          width: 18px;
          height: auto;
          cursor: pointer;
          color: #fff;
          font-size: 10px;
        }
        .SBThumbnail {
          background: #f3f3f3;
          border-radius: 15px;
          overflow: hidden;
          font-size: 13px;
          border: 2px solid #fff;
          box-shadow: 0 0 5px #efefef;
          position: relative;
          cursor: pointer;
        }
        .SBThumbnail.drag-active {
          opacity: 0.4;
        }
        .loading {
          position: absolute;
          bottom: 0;
          right: 0;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          pointer-events: none;
          background: rgba(255, 255, 255, 0.5);
        }
        .loading > :global(*) {
          margin: auto;
        }
        .SBThumbnail.selected {
          border: 2px solid #000;
        }
        .SBThumbnail:hover {
          box-shadow: 0 0 10px #ccc;
        }
        img {
          width: 100%;
          height: auto;
        }
        .btn {
          margin-right: 5px;
        }
        .content-container {
          padding: 14px;
          text-align: left;
        }
        .title {
          font-size: 1em;
          height: 20px;
        }

        .subtitle {
          font-size: 0.9em;
          height: 18px;
        }
        .info {
          margin-top: 10px;
          height: auto;
        }
        .title,
        .subtitle {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .actions {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          border-top: 1px solid #e5e5e5;
          padding-top: 14px;
        }

        .delete-button {
          display: none;
          position: absolute;
          top: 5px;
          right: 5px;
          background: #fff;
          border-radius: 100px;
        }
        .SBThumbnail:hover .delete-button {
          display: block;
        }
        .sb-restore,
        .sb-delete {
          width: 80px;
          margin: 2px;
        }
        .tag-label {
          position: absolute;
          background: #000;
          border-radius: 4px;
          color: #fff;
          padding: 2px 5px;
          display: flex;
          align-items: center;
        }
        .tag-label :global(svg) {
          width: 17px;
          height: auto;
        }
        .tag-favorite {
          top: 5px;
          left: 5px;
        }
        .tag-activated,
        .tag-published,
        .tag-draft {
          top: 5px;
          right: 5px;
          background-color: #339a22;
        }
        .tag-draft {
          background-color: #666;
        }
        .tag-published {
          background-color: #22739a;
        }
        .tag-visibility {
          left: 0;
          bottom: 0;
          border-radius: 0 4px 0 0;
        }
        .delete-mark {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 1;
          display: flex;
          align-items: center;
          background: rgba(255, 255, 255, 0.8);
          color: #fff;
          font-size: 2em;
          pointer-events: none;
        }
        .delete-mark :global(svg) {
          margin: auto;
          width: 40%;
          height: auto;
        }
        .deleted {
          opacity: 0.4;
        }
        .deleted:hover {
          opacity: 1;
        }
        .deleted:hover .delete-mark {
          display: none;
        }
        :global(.dragging-elements) {
          width: 249px;
          height: 60px;
          overflow: hidden;
          transform: translateX(-100%);
        }
        :global(.dragging-element) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: white;
          padding: 8px;
          border-radius: 10px;
          width: 100%;
          overflow: hidden;
          z-index: 2;
        }
        :global(.dragging-element .file-name) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 140px;
          font-size: 10px;
          display: inline-block;
        }
        :global(.dragging-element .number) {
          background: #000000;
          padding: 4px;
          border-radius: 50%;
          color: #fff;
          width: 30px;
          height: 30px;
          text-align: center;
        }
        :global(.dragging-elements-item) {
          height: 46px;
        }
        :global(.additional-storybook) {
          border-radius: 10px;
          background: white;
          transform: translateX(-50%);
          left: 50%;
          top: 6px;
          width: calc(100% - 8px);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.23);
        }
      `}</style>
    </div>
  );
};

export default SBThumbnail;
