import { TLanguage } from '../../mappers/polotno';
import { HISTORY_SCENES_STORED } from '../../utils/constants';

function findAndRemoveOldestScene(scenes) {
  let oldestId = null;
  let oldestTime = Infinity;

  Object.keys(scenes).forEach((sceneId) => {
    const ts = scenes[sceneId]?.ts;
    if (ts && ts < oldestTime) {
      oldestTime = ts;
      oldestId = sceneId;
    }
  });

  if (oldestId) {
    delete scenes[oldestId];
  }
  return scenes;
}

export const getScenesLocalStorage = () => {
  return localStorage.getItem(HISTORY_SCENES_STORED) || '{}';
};

export const setScenesLocalStorage = (scenes: any) => {
  if (!scenes) return;
  return localStorage.setItem(HISTORY_SCENES_STORED, JSON.stringify(scenes));
};

export const addSceneToLocalStorage = (
  store,
  sceneId: string,
  activeLanguage: TLanguage
) => {
  if (!store || !sceneId) return;
  let scenesBackup = JSON.parse(getScenesLocalStorage());
  if (Object.keys(scenesBackup).length >= 5) {
    scenesBackup = findAndRemoveOldestScene(scenesBackup);
  }

  if (scenesBackup[sceneId]) {
    if (scenesBackup[sceneId][activeLanguage]) {
      scenesBackup[sceneId][activeLanguage] = store;
    } else {
      scenesBackup[sceneId][activeLanguage] = store;
    }
    scenesBackup[sceneId].ts = Date.now();
  } else {
    scenesBackup[sceneId] = {
      [activeLanguage]: store,
      ts: Date.now()
    };
  }
  setScenesLocalStorage(scenesBackup);
};

export const removeSceneFromLocalStorage = (sceneId: string) => {
  if (!sceneId) return;
  const scenesBackup = JSON.parse(getScenesLocalStorage());
  if (scenesBackup && scenesBackup[sceneId]) {
    delete scenesBackup[sceneId];
    setScenesLocalStorage(scenesBackup);
  }
};
